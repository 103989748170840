import { CommandeData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import commande from '../../../core/sysext/Boutique/containers/Commande/params/commande.json';
import CMSView from '../../../core/sysext/CMS/components/View';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';
import Form from '../../../core/sysext/CMS/containers/Boutique/components/new/Form';

const PageCommandesUpdate: FC<PageProps & { id: string }> = props => {
  const { id, location } = props;

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  if (!id) {
    return <div>le parametre id est obligatoire</div>;
  }

  return (
    <Backoffice location={location}>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix="/boutique/commandes/"
          model={
            new CommandeData({
              boutique,
              params: commande,
            })
          }
          name="item"
          type="update"
          {...props}
        />
      </CMSView>
    </Backoffice>
  );
};

export default PageCommandesUpdate;
